import type {Emoji} from '@github-ui/markdown-editor'

const emojiCharMap: Record<string, string> = {
  '+1': '\u{1f44d}',
  '-1': '\u{1f44e}',
  100: '\u{1f4af}',
  1234: '\u{1f522}',
  '1st_place_medal': '\u{1f947}',
  '2nd_place_medal': '\u{1f948}',
  '3rd_place_medal': '\u{1f949}',
  '8ball': '\u{1f3b1}',
  a: '\u{1f170}',
  ab: '\u{1f18e}',
  abacus: '\u{1f9ee}',
  abc: '\u{1f524}',
  abcd: '\u{1f521}',
  accept: '\u{1f251}',
  adhesive_bandage: '\u{1fa79}',
  adult: '\u{1f9d1}',
  aerial_tramway: '\u{1f6a1}',
  afghanistan: '\u{1f1e6}\u{200d}\u{1f1eb}',
  airplane: '\u{2708}',
  aland_islands: '\u{1f1e6}\u{200d}\u{1f1fd}',
  alarm_clock: '\u{23f0}',
  albania: '\u{1f1e6}\u{200d}\u{1f1f1}',
  alembic: '\u{2697}',
  algeria: '\u{1f1e9}\u{200d}\u{1f1ff}',
  alien: '\u{1f47d}',
  ambulance: '\u{1f691}',
  american_samoa: '\u{1f1e6}\u{200d}\u{1f1f8}',
  amphora: '\u{1f3fa}',
  anchor: '\u{2693}',
  andorra: '\u{1f1e6}\u{200d}\u{1f1e9}',
  angel: '\u{1f47c}',
  anger: '\u{1f4a2}',
  angola: '\u{1f1e6}\u{200d}\u{1f1f4}',
  angry: '\u{1f620}',
  anguilla: '\u{1f1e6}\u{200d}\u{1f1ee}',
  anguished: '\u{1f627}',
  ant: '\u{1f41c}',
  antarctica: '\u{1f1e6}\u{200d}\u{1f1f6}',
  antigua_barbuda: '\u{1f1e6}\u{200d}\u{1f1ec}',
  apple: '\u{1f34e}',
  aquarius: '\u{2652}',
  argentina: '\u{1f1e6}\u{200d}\u{1f1f7}',
  aries: '\u{2648}',
  armenia: '\u{1f1e6}\u{200d}\u{1f1f2}',
  arrow_backward: '\u{25c0}',
  arrow_double_down: '\u{23ec}',
  arrow_double_up: '\u{23eb}',
  arrow_down: '\u{2b07}',
  arrow_down_small: '\u{1f53d}',
  arrow_forward: '\u{25b6}',
  arrow_heading_down: '\u{2935}',
  arrow_heading_up: '\u{2934}',
  arrow_left: '\u{2b05}',
  arrow_lower_left: '\u{2199}',
  arrow_lower_right: '\u{2198}',
  arrow_right: '\u{27a1}',
  arrow_right_hook: '\u{21aa}',
  arrow_up: '\u{2b06}',
  arrow_up_down: '\u{2195}',
  arrow_up_small: '\u{1f53c}',
  arrow_upper_left: '\u{2196}',
  arrow_upper_right: '\u{2197}',
  arrows_clockwise: '\u{1f503}',
  arrows_counterclockwise: '\u{1f504}',
  art: '\u{1f3a8}',
  articulated_lorry: '\u{1f69b}',
  artificial_satellite: '\u{1f6f0}',
  artist: '\u{1f9d1}\u{200d}\u{1f3a8}',
  aruba: '\u{1f1e6}\u{200d}\u{1f1fc}',
  ascension_island: '\u{1f1e6}\u{200d}\u{1f1e8}',
  asterisk: '\u{002a}\u{200d}\u{20e3}',
  astonished: '\u{1f632}',
  astronaut: '\u{1f9d1}\u{200d}\u{1f680}',
  athletic_shoe: '\u{1f45f}',
  atm: '\u{1f3e7}',
  atom_symbol: '\u{269b}',
  australia: '\u{1f1e6}\u{200d}\u{1f1fa}',
  austria: '\u{1f1e6}\u{200d}\u{1f1f9}',
  auto_rickshaw: '\u{1f6fa}',
  avocado: '\u{1f951}',
  axe: '\u{1fa93}',
  azerbaijan: '\u{1f1e6}\u{200d}\u{1f1ff}',
  b: '\u{1f171}',
  baby: '\u{1f476}',
  baby_bottle: '\u{1f37c}',
  baby_chick: '\u{1f424}',
  baby_symbol: '\u{1f6bc}',
  back: '\u{1f519}',
  bacon: '\u{1f953}',
  badger: '\u{1f9a1}',
  badminton: '\u{1f3f8}',
  bagel: '\u{1f96f}',
  baggage_claim: '\u{1f6c4}',
  baguette_bread: '\u{1f956}',
  bahamas: '\u{1f1e7}\u{200d}\u{1f1f8}',
  bahrain: '\u{1f1e7}\u{200d}\u{1f1ed}',
  balance_scale: '\u{2696}',
  bald_man: '\u{1f468}\u{200d}\u{1f9b2}',
  bald_woman: '\u{1f469}\u{200d}\u{1f9b2}',
  ballet_shoes: '\u{1fa70}',
  balloon: '\u{1f388}',
  ballot_box: '\u{1f5f3}',
  ballot_box_with_check: '\u{2611}',
  bamboo: '\u{1f38d}',
  banana: '\u{1f34c}',
  bangbang: '\u{203c}',
  bangladesh: '\u{1f1e7}\u{200d}\u{1f1e9}',
  banjo: '\u{1fa95}',
  bank: '\u{1f3e6}',
  bar_chart: '\u{1f4ca}',
  barbados: '\u{1f1e7}\u{200d}\u{1f1e7}',
  barber: '\u{1f488}',
  baseball: '\u{26be}',
  basket: '\u{1f9fa}',
  basketball: '\u{1f3c0}',
  basketball_man: '\u{26f9}\u{200d}\u{2642}',
  basketball_woman: '\u{26f9}\u{200d}\u{2640}',
  bat: '\u{1f987}',
  bath: '\u{1f6c0}',
  bathtub: '\u{1f6c1}',
  battery: '\u{1f50b}',
  beach_umbrella: '\u{1f3d6}',
  bear: '\u{1f43b}',
  bearded_person: '\u{1f9d4}',
  bed: '\u{1f6cf}',
  bee: '\u{1f41d}',
  beer: '\u{1f37a}',
  beers: '\u{1f37b}',
  beetle: '\u{1f41e}',
  beginner: '\u{1f530}',
  belarus: '\u{1f1e7}\u{200d}\u{1f1fe}',
  belgium: '\u{1f1e7}\u{200d}\u{1f1ea}',
  belize: '\u{1f1e7}\u{200d}\u{1f1ff}',
  bell: '\u{1f514}',
  bellhop_bell: '\u{1f6ce}',
  benin: '\u{1f1e7}\u{200d}\u{1f1ef}',
  bento: '\u{1f371}',
  bermuda: '\u{1f1e7}\u{200d}\u{1f1f2}',
  beverage_box: '\u{1f9c3}',
  bhutan: '\u{1f1e7}\u{200d}\u{1f1f9}',
  bicyclist: '\u{1f6b4}',
  bike: '\u{1f6b2}',
  biking_man: '\u{1f6b4}\u{200d}\u{2642}',
  biking_woman: '\u{1f6b4}\u{200d}\u{2640}',
  bikini: '\u{1f459}',
  billed_cap: '\u{1f9e2}',
  biohazard: '\u{2623}',
  bird: '\u{1f426}',
  birthday: '\u{1f382}',
  black_circle: '\u{26ab}',
  black_flag: '\u{1f3f4}',
  black_heart: '\u{1f5a4}',
  black_joker: '\u{1f0cf}',
  black_large_square: '\u{2b1b}',
  black_medium_small_square: '\u{25fe}',
  black_medium_square: '\u{25fc}',
  black_nib: '\u{2712}',
  black_small_square: '\u{25aa}',
  black_square_button: '\u{1f532}',
  blond_haired_man: '\u{1f471}\u{200d}\u{2642}',
  blond_haired_person: '\u{1f471}',
  blond_haired_woman: '\u{1f471}\u{200d}\u{2640}',
  blonde_woman: '\u{1f471}\u{200d}\u{2640}',
  blossom: '\u{1f33c}',
  blowfish: '\u{1f421}',
  blue_book: '\u{1f4d8}',
  blue_car: '\u{1f699}',
  blue_heart: '\u{1f499}',
  blue_square: '\u{1f7e6}',
  blush: '\u{1f60a}',
  boar: '\u{1f417}',
  boat: '\u{26f5}',
  bolivia: '\u{1f1e7}\u{200d}\u{1f1f4}',
  bomb: '\u{1f4a3}',
  bone: '\u{1f9b4}',
  book: '\u{1f4d6}',
  bookmark: '\u{1f516}',
  bookmark_tabs: '\u{1f4d1}',
  books: '\u{1f4da}',
  boom: '\u{1f4a5}',
  boot: '\u{1f462}',
  bosnia_herzegovina: '\u{1f1e7}\u{200d}\u{1f1e6}',
  botswana: '\u{1f1e7}\u{200d}\u{1f1fc}',
  bouncing_ball_man: '\u{26f9}\u{200d}\u{2642}',
  bouncing_ball_person: '\u{26f9}',
  bouncing_ball_woman: '\u{26f9}\u{200d}\u{2640}',
  bouquet: '\u{1f490}',
  bouvet_island: '\u{1f1e7}\u{200d}\u{1f1fb}',
  bow: '\u{1f647}',
  bow_and_arrow: '\u{1f3f9}',
  bowing_man: '\u{1f647}\u{200d}\u{2642}',
  bowing_woman: '\u{1f647}\u{200d}\u{2640}',
  bowl_with_spoon: '\u{1f963}',
  bowling: '\u{1f3b3}',
  boxing_glove: '\u{1f94a}',
  boy: '\u{1f466}',
  brain: '\u{1f9e0}',
  brazil: '\u{1f1e7}\u{200d}\u{1f1f7}',
  bread: '\u{1f35e}',
  breast_feeding: '\u{1f931}',
  bricks: '\u{1f9f1}',
  bride_with_veil: '\u{1f470}',
  bridge_at_night: '\u{1f309}',
  briefcase: '\u{1f4bc}',
  british_indian_ocean_territory: '\u{1f1ee}\u{200d}\u{1f1f4}',
  british_virgin_islands: '\u{1f1fb}\u{200d}\u{1f1ec}',
  broccoli: '\u{1f966}',
  broken_heart: '\u{1f494}',
  broom: '\u{1f9f9}',
  brown_circle: '\u{1f7e4}',
  brown_heart: '\u{1f90e}',
  brown_square: '\u{1f7eb}',
  brunei: '\u{1f1e7}\u{200d}\u{1f1f3}',
  bug: '\u{1f41b}',
  building_construction: '\u{1f3d7}',
  bulb: '\u{1f4a1}',
  bulgaria: '\u{1f1e7}\u{200d}\u{1f1ec}',
  bullettrain_front: '\u{1f685}',
  bullettrain_side: '\u{1f684}',
  burkina_faso: '\u{1f1e7}\u{200d}\u{1f1eb}',
  burrito: '\u{1f32f}',
  burundi: '\u{1f1e7}\u{200d}\u{1f1ee}',
  bus: '\u{1f68c}',
  business_suit_levitating: '\u{1f574}',
  busstop: '\u{1f68f}',
  bust_in_silhouette: '\u{1f464}',
  busts_in_silhouette: '\u{1f465}',
  butter: '\u{1f9c8}',
  butterfly: '\u{1f98b}',
  cactus: '\u{1f335}',
  cake: '\u{1f370}',
  calendar: '\u{1f4c6}',
  call_me_hand: '\u{1f919}',
  calling: '\u{1f4f2}',
  cambodia: '\u{1f1f0}\u{200d}\u{1f1ed}',
  camel: '\u{1f42b}',
  camera: '\u{1f4f7}',
  camera_flash: '\u{1f4f8}',
  cameroon: '\u{1f1e8}\u{200d}\u{1f1f2}',
  camping: '\u{1f3d5}',
  canada: '\u{1f1e8}\u{200d}\u{1f1e6}',
  canary_islands: '\u{1f1ee}\u{200d}\u{1f1e8}',
  cancer: '\u{264b}',
  candle: '\u{1f56f}',
  candy: '\u{1f36c}',
  canned_food: '\u{1f96b}',
  canoe: '\u{1f6f6}',
  cape_verde: '\u{1f1e8}\u{200d}\u{1f1fb}',
  capital_abcd: '\u{1f520}',
  capricorn: '\u{2651}',
  car: '\u{1f697}',
  card_file_box: '\u{1f5c3}',
  card_index: '\u{1f4c7}',
  card_index_dividers: '\u{1f5c2}',
  caribbean_netherlands: '\u{1f1e7}\u{200d}\u{1f1f6}',
  carousel_horse: '\u{1f3a0}',
  carrot: '\u{1f955}',
  cartwheeling: '\u{1f938}',
  cat: '\u{1f431}',
  cat2: '\u{1f408}',
  cayman_islands: '\u{1f1f0}\u{200d}\u{1f1fe}',
  cd: '\u{1f4bf}',
  central_african_republic: '\u{1f1e8}\u{200d}\u{1f1eb}',
  ceuta_melilla: '\u{1f1ea}\u{200d}\u{1f1e6}',
  chad: '\u{1f1f9}\u{200d}\u{1f1e9}',
  chains: '\u{26d3}',
  chair: '\u{1fa91}',
  champagne: '\u{1f37e}',
  chart: '\u{1f4b9}',
  chart_with_downwards_trend: '\u{1f4c9}',
  chart_with_upwards_trend: '\u{1f4c8}',
  checkered_flag: '\u{1f3c1}',
  cheese: '\u{1f9c0}',
  cherries: '\u{1f352}',
  cherry_blossom: '\u{1f338}',
  chess_pawn: '\u{265f}',
  chestnut: '\u{1f330}',
  chicken: '\u{1f414}',
  child: '\u{1f9d2}',
  children_crossing: '\u{1f6b8}',
  chile: '\u{1f1e8}\u{200d}\u{1f1f1}',
  chipmunk: '\u{1f43f}',
  chocolate_bar: '\u{1f36b}',
  chopsticks: '\u{1f962}',
  christmas_island: '\u{1f1e8}\u{200d}\u{1f1fd}',
  christmas_tree: '\u{1f384}',
  church: '\u{26ea}',
  cinema: '\u{1f3a6}',
  circus_tent: '\u{1f3aa}',
  city_sunrise: '\u{1f307}',
  city_sunset: '\u{1f306}',
  cityscape: '\u{1f3d9}',
  cl: '\u{1f191}',
  clamp: '\u{1f5dc}',
  clap: '\u{1f44f}',
  clapper: '\u{1f3ac}',
  classical_building: '\u{1f3db}',
  climbing: '\u{1f9d7}',
  climbing_man: '\u{1f9d7}\u{200d}\u{2642}',
  climbing_woman: '\u{1f9d7}\u{200d}\u{2640}',
  clinking_glasses: '\u{1f942}',
  clipboard: '\u{1f4cb}',
  clipperton_island: '\u{1f1e8}\u{200d}\u{1f1f5}',
  clock1: '\u{1f550}',
  clock10: '\u{1f559}',
  clock1030: '\u{1f565}',
  clock11: '\u{1f55a}',
  clock1130: '\u{1f566}',
  clock12: '\u{1f55b}',
  clock1230: '\u{1f567}',
  clock130: '\u{1f55c}',
  clock2: '\u{1f551}',
  clock230: '\u{1f55d}',
  clock3: '\u{1f552}',
  clock330: '\u{1f55e}',
  clock4: '\u{1f553}',
  clock430: '\u{1f55f}',
  clock5: '\u{1f554}',
  clock530: '\u{1f560}',
  clock6: '\u{1f555}',
  clock630: '\u{1f561}',
  clock7: '\u{1f556}',
  clock730: '\u{1f562}',
  clock8: '\u{1f557}',
  clock830: '\u{1f563}',
  clock9: '\u{1f558}',
  clock930: '\u{1f564}',
  closed_book: '\u{1f4d5}',
  closed_lock_with_key: '\u{1f510}',
  closed_umbrella: '\u{1f302}',
  cloud: '\u{2601}',
  cloud_with_lightning: '\u{1f329}',
  cloud_with_lightning_and_rain: '\u{26c8}',
  cloud_with_rain: '\u{1f327}',
  cloud_with_snow: '\u{1f328}',
  clown_face: '\u{1f921}',
  clubs: '\u{2663}',
  cn: '\u{1f1e8}\u{200d}\u{1f1f3}',
  coat: '\u{1f9e5}',
  cocktail: '\u{1f378}',
  coconut: '\u{1f965}',
  cocos_islands: '\u{1f1e8}\u{200d}\u{1f1e8}',
  coffee: '\u{2615}',
  coffin: '\u{26b0}',
  cold_face: '\u{1f976}',
  cold_sweat: '\u{1f630}',
  collision: '\u{1f4a5}',
  colombia: '\u{1f1e8}\u{200d}\u{1f1f4}',
  comet: '\u{2604}',
  comoros: '\u{1f1f0}\u{200d}\u{1f1f2}',
  compass: '\u{1f9ed}',
  computer: '\u{1f4bb}',
  computer_mouse: '\u{1f5b1}',
  confetti_ball: '\u{1f38a}',
  confounded: '\u{1f616}',
  confused: '\u{1f615}',
  congo_brazzaville: '\u{1f1e8}\u{200d}\u{1f1ec}',
  congo_kinshasa: '\u{1f1e8}\u{200d}\u{1f1e9}',
  congratulations: '\u{3297}',
  construction: '\u{1f6a7}',
  construction_worker: '\u{1f477}',
  construction_worker_man: '\u{1f477}\u{200d}\u{2642}',
  construction_worker_woman: '\u{1f477}\u{200d}\u{2640}',
  control_knobs: '\u{1f39b}',
  convenience_store: '\u{1f3ea}',
  cook: '\u{1f9d1}\u{200d}\u{1f373}',
  cook_islands: '\u{1f1e8}\u{200d}\u{1f1f0}',
  cookie: '\u{1f36a}',
  cool: '\u{1f192}',
  cop: '\u{1f46e}',
  copyright: '\u{00a9}',
  corn: '\u{1f33d}',
  costa_rica: '\u{1f1e8}\u{200d}\u{1f1f7}',
  cote_divoire: '\u{1f1e8}\u{200d}\u{1f1ee}',
  couch_and_lamp: '\u{1f6cb}',
  couple: '\u{1f46b}',
  couple_with_heart: '\u{1f491}',
  couple_with_heart_man_man: '\u{1f468}\u{200d}\u{2764}\u{200d}\u{1f468}',
  couple_with_heart_woman_man: '\u{1f469}\u{200d}\u{2764}\u{200d}\u{1f468}',
  couple_with_heart_woman_woman: '\u{1f469}\u{200d}\u{2764}\u{200d}\u{1f469}',
  couplekiss: '\u{1f48f}',
  couplekiss_man_man: '\u{1f468}\u{200d}\u{2764}\u{200d}\u{1f48b}\u{200d}\u{1f468}',
  couplekiss_man_woman: '\u{1f469}\u{200d}\u{2764}\u{200d}\u{1f48b}\u{200d}\u{1f468}',
  couplekiss_woman_woman: '\u{1f469}\u{200d}\u{2764}\u{200d}\u{1f48b}\u{200d}\u{1f469}',
  cow: '\u{1f42e}',
  cow2: '\u{1f404}',
  cowboy_hat_face: '\u{1f920}',
  crab: '\u{1f980}',
  crayon: '\u{1f58d}',
  credit_card: '\u{1f4b3}',
  crescent_moon: '\u{1f319}',
  cricket: '\u{1f997}',
  cricket_game: '\u{1f3cf}',
  croatia: '\u{1f1ed}\u{200d}\u{1f1f7}',
  crocodile: '\u{1f40a}',
  croissant: '\u{1f950}',
  crossed_fingers: '\u{1f91e}',
  crossed_flags: '\u{1f38c}',
  crossed_swords: '\u{2694}',
  crown: '\u{1f451}',
  cry: '\u{1f622}',
  crying_cat_face: '\u{1f63f}',
  crystal_ball: '\u{1f52e}',
  cuba: '\u{1f1e8}\u{200d}\u{1f1fa}',
  cucumber: '\u{1f952}',
  cup_with_straw: '\u{1f964}',
  cupcake: '\u{1f9c1}',
  cupid: '\u{1f498}',
  curacao: '\u{1f1e8}\u{200d}\u{1f1fc}',
  curling_stone: '\u{1f94c}',
  curly_haired_man: '\u{1f468}\u{200d}\u{1f9b1}',
  curly_haired_woman: '\u{1f469}\u{200d}\u{1f9b1}',
  curly_loop: '\u{27b0}',
  currency_exchange: '\u{1f4b1}',
  curry: '\u{1f35b}',
  cursing_face: '\u{1f92c}',
  custard: '\u{1f36e}',
  customs: '\u{1f6c3}',
  cut_of_meat: '\u{1f969}',
  cyclone: '\u{1f300}',
  cyprus: '\u{1f1e8}\u{200d}\u{1f1fe}',
  czech_republic: '\u{1f1e8}\u{200d}\u{1f1ff}',
  dagger: '\u{1f5e1}',
  dancer: '\u{1f483}',
  dancers: '\u{1f46f}',
  dancing_men: '\u{1f46f}\u{200d}\u{2642}',
  dancing_women: '\u{1f46f}\u{200d}\u{2640}',
  dango: '\u{1f361}',
  dark_sunglasses: '\u{1f576}',
  dart: '\u{1f3af}',
  dash: '\u{1f4a8}',
  date: '\u{1f4c5}',
  de: '\u{1f1e9}\u{200d}\u{1f1ea}',
  deaf_man: '\u{1f9cf}\u{200d}\u{2642}',
  deaf_person: '\u{1f9cf}',
  deaf_woman: '\u{1f9cf}\u{200d}\u{2640}',
  deciduous_tree: '\u{1f333}',
  deer: '\u{1f98c}',
  denmark: '\u{1f1e9}\u{200d}\u{1f1f0}',
  department_store: '\u{1f3ec}',
  derelict_house: '\u{1f3da}',
  desert: '\u{1f3dc}',
  desert_island: '\u{1f3dd}',
  desktop_computer: '\u{1f5a5}',
  detective: '\u{1f575}',
  diamond_shape_with_a_dot_inside: '\u{1f4a0}',
  diamonds: '\u{2666}',
  diego_garcia: '\u{1f1e9}\u{200d}\u{1f1ec}',
  disappointed: '\u{1f61e}',
  disappointed_relieved: '\u{1f625}',
  diving_mask: '\u{1f93f}',
  diya_lamp: '\u{1fa94}',
  dizzy: '\u{1f4ab}',
  dizzy_face: '\u{1f635}',
  djibouti: '\u{1f1e9}\u{200d}\u{1f1ef}',
  dna: '\u{1f9ec}',
  do_not_litter: '\u{1f6af}',
  dog: '\u{1f436}',
  dog2: '\u{1f415}',
  dollar: '\u{1f4b5}',
  dolls: '\u{1f38e}',
  dolphin: '\u{1f42c}',
  dominica: '\u{1f1e9}\u{200d}\u{1f1f2}',
  dominican_republic: '\u{1f1e9}\u{200d}\u{1f1f4}',
  door: '\u{1f6aa}',
  doughnut: '\u{1f369}',
  dove: '\u{1f54a}',
  dragon: '\u{1f409}',
  dragon_face: '\u{1f432}',
  dress: '\u{1f457}',
  dromedary_camel: '\u{1f42a}',
  drooling_face: '\u{1f924}',
  drop_of_blood: '\u{1fa78}',
  droplet: '\u{1f4a7}',
  drum: '\u{1f941}',
  duck: '\u{1f986}',
  dumpling: '\u{1f95f}',
  dvd: '\u{1f4c0}',
  'e-mail': '\u{1f4e7}',
  eagle: '\u{1f985}',
  ear: '\u{1f442}',
  ear_of_rice: '\u{1f33e}',
  ear_with_hearing_aid: '\u{1f9bb}',
  earth_africa: '\u{1f30d}',
  earth_americas: '\u{1f30e}',
  earth_asia: '\u{1f30f}',
  ecuador: '\u{1f1ea}\u{200d}\u{1f1e8}',
  egg: '\u{1f95a}',
  eggplant: '\u{1f346}',
  egypt: '\u{1f1ea}\u{200d}\u{1f1ec}',
  eight: '\u{0038}\u{200d}\u{20e3}',
  eight_pointed_black_star: '\u{2734}',
  eight_spoked_asterisk: '\u{2733}',
  eject_button: '\u{23cf}',
  el_salvador: '\u{1f1f8}\u{200d}\u{1f1fb}',
  electric_plug: '\u{1f50c}',
  elephant: '\u{1f418}',
  elf: '\u{1f9dd}',
  elf_man: '\u{1f9dd}\u{200d}\u{2642}',
  elf_woman: '\u{1f9dd}\u{200d}\u{2640}',
  email: '\u{2709}',
  end: '\u{1f51a}',
  england:
    '\u{1f3f4}\u{200d}\u{e0067}\u{200d}\u{e0062}\u{200d}\u{e0065}\u{200d}\u{e006e}\u{200d}\u{e0067}\u{200d}\u{e007f}',
  envelope: '\u{2709}',
  envelope_with_arrow: '\u{1f4e9}',
  equatorial_guinea: '\u{1f1ec}\u{200d}\u{1f1f6}',
  eritrea: '\u{1f1ea}\u{200d}\u{1f1f7}',
  es: '\u{1f1ea}\u{200d}\u{1f1f8}',
  estonia: '\u{1f1ea}\u{200d}\u{1f1ea}',
  ethiopia: '\u{1f1ea}\u{200d}\u{1f1f9}',
  eu: '\u{1f1ea}\u{200d}\u{1f1fa}',
  euro: '\u{1f4b6}',
  european_castle: '\u{1f3f0}',
  european_post_office: '\u{1f3e4}',
  european_union: '\u{1f1ea}\u{200d}\u{1f1fa}',
  evergreen_tree: '\u{1f332}',
  exclamation: '\u{2757}',
  exploding_head: '\u{1f92f}',
  expressionless: '\u{1f611}',
  eye: '\u{1f441}',
  eye_speech_bubble: '\u{1f441}\u{200d}\u{1f5e8}',
  eyeglasses: '\u{1f453}',
  eyes: '\u{1f440}',
  face_with_head_bandage: '\u{1f915}',
  face_with_thermometer: '\u{1f912}',
  facepalm: '\u{1f926}',
  facepunch: '\u{1f44a}',
  factory: '\u{1f3ed}',
  factory_worker: '\u{1f9d1}\u{200d}\u{1f3ed}',
  fairy: '\u{1f9da}',
  fairy_man: '\u{1f9da}\u{200d}\u{2642}',
  fairy_woman: '\u{1f9da}\u{200d}\u{2640}',
  falafel: '\u{1f9c6}',
  falkland_islands: '\u{1f1eb}\u{200d}\u{1f1f0}',
  fallen_leaf: '\u{1f342}',
  family: '\u{1f46a}',
  family_man_boy: '\u{1f468}\u{200d}\u{1f466}',
  family_man_boy_boy: '\u{1f468}\u{200d}\u{1f466}\u{200d}\u{1f466}',
  family_man_girl: '\u{1f468}\u{200d}\u{1f467}',
  family_man_girl_boy: '\u{1f468}\u{200d}\u{1f467}\u{200d}\u{1f466}',
  family_man_girl_girl: '\u{1f468}\u{200d}\u{1f467}\u{200d}\u{1f467}',
  family_man_man_boy: '\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f466}',
  family_man_man_boy_boy: '\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f466}\u{200d}\u{1f466}',
  family_man_man_girl: '\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f467}',
  family_man_man_girl_boy: '\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f467}\u{200d}\u{1f466}',
  family_man_man_girl_girl: '\u{1f468}\u{200d}\u{1f468}\u{200d}\u{1f467}\u{200d}\u{1f467}',
  family_man_woman_boy: '\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f466}',
  family_man_woman_boy_boy: '\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f466}\u{200d}\u{1f466}',
  family_man_woman_girl: '\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f467}',
  family_man_woman_girl_boy: '\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f466}',
  family_man_woman_girl_girl: '\u{1f468}\u{200d}\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f467}',
  family_woman_boy: '\u{1f469}\u{200d}\u{1f466}',
  family_woman_boy_boy: '\u{1f469}\u{200d}\u{1f466}\u{200d}\u{1f466}',
  family_woman_girl: '\u{1f469}\u{200d}\u{1f467}',
  family_woman_girl_boy: '\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f466}',
  family_woman_girl_girl: '\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f467}',
  family_woman_woman_boy: '\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f466}',
  family_woman_woman_boy_boy: '\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f466}\u{200d}\u{1f466}',
  family_woman_woman_girl: '\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f467}',
  family_woman_woman_girl_boy: '\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f466}',
  family_woman_woman_girl_girl: '\u{1f469}\u{200d}\u{1f469}\u{200d}\u{1f467}\u{200d}\u{1f467}',
  farmer: '\u{1f9d1}\u{200d}\u{1f33e}',
  faroe_islands: '\u{1f1eb}\u{200d}\u{1f1f4}',
  fast_forward: '\u{23e9}',
  fax: '\u{1f4e0}',
  fearful: '\u{1f628}',
  feet: '\u{1f43e}',
  female_detective: '\u{1f575}\u{200d}\u{2640}',
  female_sign: '\u{2640}',
  ferris_wheel: '\u{1f3a1}',
  ferry: '\u{26f4}',
  field_hockey: '\u{1f3d1}',
  fiji: '\u{1f1eb}\u{200d}\u{1f1ef}',
  file_cabinet: '\u{1f5c4}',
  file_folder: '\u{1f4c1}',
  film_projector: '\u{1f4fd}',
  film_strip: '\u{1f39e}',
  finland: '\u{1f1eb}\u{200d}\u{1f1ee}',
  fire: '\u{1f525}',
  fire_engine: '\u{1f692}',
  fire_extinguisher: '\u{1f9ef}',
  firecracker: '\u{1f9e8}',
  firefighter: '\u{1f9d1}\u{200d}\u{1f692}',
  fireworks: '\u{1f386}',
  first_quarter_moon: '\u{1f313}',
  first_quarter_moon_with_face: '\u{1f31b}',
  fish: '\u{1f41f}',
  fish_cake: '\u{1f365}',
  fishing_pole_and_fish: '\u{1f3a3}',
  fist: '\u{270a}',
  fist_left: '\u{1f91b}',
  fist_oncoming: '\u{1f44a}',
  fist_raised: '\u{270a}',
  fist_right: '\u{1f91c}',
  five: '\u{0035}\u{200d}\u{20e3}',
  flags: '\u{1f38f}',
  flamingo: '\u{1f9a9}',
  flashlight: '\u{1f526}',
  flat_shoe: '\u{1f97f}',
  fleur_de_lis: '\u{269c}',
  flight_arrival: '\u{1f6ec}',
  flight_departure: '\u{1f6eb}',
  flipper: '\u{1f42c}',
  floppy_disk: '\u{1f4be}',
  flower_playing_cards: '\u{1f3b4}',
  flushed: '\u{1f633}',
  flying_disc: '\u{1f94f}',
  flying_saucer: '\u{1f6f8}',
  fog: '\u{1f32b}',
  foggy: '\u{1f301}',
  foot: '\u{1f9b6}',
  football: '\u{1f3c8}',
  footprints: '\u{1f463}',
  fork_and_knife: '\u{1f374}',
  fortune_cookie: '\u{1f960}',
  fountain: '\u{26f2}',
  fountain_pen: '\u{1f58b}',
  four: '\u{0034}\u{200d}\u{20e3}',
  four_leaf_clover: '\u{1f340}',
  fox_face: '\u{1f98a}',
  fr: '\u{1f1eb}\u{200d}\u{1f1f7}',
  framed_picture: '\u{1f5bc}',
  free: '\u{1f193}',
  french_guiana: '\u{1f1ec}\u{200d}\u{1f1eb}',
  french_polynesia: '\u{1f1f5}\u{200d}\u{1f1eb}',
  french_southern_territories: '\u{1f1f9}\u{200d}\u{1f1eb}',
  fried_egg: '\u{1f373}',
  fried_shrimp: '\u{1f364}',
  fries: '\u{1f35f}',
  frog: '\u{1f438}',
  frowning: '\u{1f626}',
  frowning_face: '\u{2639}',
  frowning_man: '\u{1f64d}\u{200d}\u{2642}',
  frowning_person: '\u{1f64d}',
  frowning_woman: '\u{1f64d}\u{200d}\u{2640}',
  fu: '\u{1f595}',
  fuelpump: '\u{26fd}',
  full_moon: '\u{1f315}',
  full_moon_with_face: '\u{1f31d}',
  funeral_urn: '\u{26b1}',
  gabon: '\u{1f1ec}\u{200d}\u{1f1e6}',
  gambia: '\u{1f1ec}\u{200d}\u{1f1f2}',
  game_die: '\u{1f3b2}',
  garlic: '\u{1f9c4}',
  gb: '\u{1f1ec}\u{200d}\u{1f1e7}',
  gear: '\u{2699}',
  gem: '\u{1f48e}',
  gemini: '\u{264a}',
  genie: '\u{1f9de}',
  genie_man: '\u{1f9de}\u{200d}\u{2642}',
  genie_woman: '\u{1f9de}\u{200d}\u{2640}',
  georgia: '\u{1f1ec}\u{200d}\u{1f1ea}',
  ghana: '\u{1f1ec}\u{200d}\u{1f1ed}',
  ghost: '\u{1f47b}',
  gibraltar: '\u{1f1ec}\u{200d}\u{1f1ee}',
  gift: '\u{1f381}',
  gift_heart: '\u{1f49d}',
  giraffe: '\u{1f992}',
  girl: '\u{1f467}',
  globe_with_meridians: '\u{1f310}',
  gloves: '\u{1f9e4}',
  goal_net: '\u{1f945}',
  goat: '\u{1f410}',
  goggles: '\u{1f97d}',
  golf: '\u{26f3}',
  golfing: '\u{1f3cc}',
  golfing_man: '\u{1f3cc}\u{200d}\u{2642}',
  golfing_woman: '\u{1f3cc}\u{200d}\u{2640}',
  gorilla: '\u{1f98d}',
  grapes: '\u{1f347}',
  greece: '\u{1f1ec}\u{200d}\u{1f1f7}',
  green_apple: '\u{1f34f}',
  green_book: '\u{1f4d7}',
  green_circle: '\u{1f7e2}',
  green_heart: '\u{1f49a}',
  green_salad: '\u{1f957}',
  green_square: '\u{1f7e9}',
  greenland: '\u{1f1ec}\u{200d}\u{1f1f1}',
  grenada: '\u{1f1ec}\u{200d}\u{1f1e9}',
  grey_exclamation: '\u{2755}',
  grey_question: '\u{2754}',
  grimacing: '\u{1f62c}',
  grin: '\u{1f601}',
  grinning: '\u{1f600}',
  guadeloupe: '\u{1f1ec}\u{200d}\u{1f1f5}',
  guam: '\u{1f1ec}\u{200d}\u{1f1fa}',
  guard: '\u{1f482}',
  guardsman: '\u{1f482}\u{200d}\u{2642}',
  guardswoman: '\u{1f482}\u{200d}\u{2640}',
  guatemala: '\u{1f1ec}\u{200d}\u{1f1f9}',
  guernsey: '\u{1f1ec}\u{200d}\u{1f1ec}',
  guide_dog: '\u{1f9ae}',
  guinea: '\u{1f1ec}\u{200d}\u{1f1f3}',
  guinea_bissau: '\u{1f1ec}\u{200d}\u{1f1fc}',
  guitar: '\u{1f3b8}',
  gun: '\u{1f52b}',
  guyana: '\u{1f1ec}\u{200d}\u{1f1fe}',
  haircut: '\u{1f487}',
  haircut_man: '\u{1f487}\u{200d}\u{2642}',
  haircut_woman: '\u{1f487}\u{200d}\u{2640}',
  haiti: '\u{1f1ed}\u{200d}\u{1f1f9}',
  hamburger: '\u{1f354}',
  hammer: '\u{1f528}',
  hammer_and_pick: '\u{2692}',
  hammer_and_wrench: '\u{1f6e0}',
  hamster: '\u{1f439}',
  hand: '\u{270b}',
  hand_over_mouth: '\u{1f92d}',
  handbag: '\u{1f45c}',
  handball_person: '\u{1f93e}',
  handshake: '\u{1f91d}',
  hankey: '\u{1f4a9}',
  hash: '\u{0023}\u{200d}\u{20e3}',
  hatched_chick: '\u{1f425}',
  hatching_chick: '\u{1f423}',
  headphones: '\u{1f3a7}',
  health_worker: '\u{1f9d1}\u{200d}\u{2695}',
  hear_no_evil: '\u{1f649}',
  heard_mcdonald_islands: '\u{1f1ed}\u{200d}\u{1f1f2}',
  heart: '\u{2764}',
  heart_decoration: '\u{1f49f}',
  heart_eyes: '\u{1f60d}',
  heart_eyes_cat: '\u{1f63b}',
  heartbeat: '\u{1f493}',
  heartpulse: '\u{1f497}',
  hearts: '\u{2665}',
  heavy_check_mark: '\u{2714}',
  heavy_division_sign: '\u{2797}',
  heavy_dollar_sign: '\u{1f4b2}',
  heavy_exclamation_mark: '\u{2757}',
  heavy_heart_exclamation: '\u{2763}',
  heavy_minus_sign: '\u{2796}',
  heavy_multiplication_x: '\u{2716}',
  heavy_plus_sign: '\u{2795}',
  hedgehog: '\u{1f994}',
  helicopter: '\u{1f681}',
  herb: '\u{1f33f}',
  hibiscus: '\u{1f33a}',
  high_brightness: '\u{1f506}',
  high_heel: '\u{1f460}',
  hiking_boot: '\u{1f97e}',
  hindu_temple: '\u{1f6d5}',
  hippopotamus: '\u{1f99b}',
  hocho: '\u{1f52a}',
  hole: '\u{1f573}',
  honduras: '\u{1f1ed}\u{200d}\u{1f1f3}',
  honey_pot: '\u{1f36f}',
  honeybee: '\u{1f41d}',
  hong_kong: '\u{1f1ed}\u{200d}\u{1f1f0}',
  horse: '\u{1f434}',
  horse_racing: '\u{1f3c7}',
  hospital: '\u{1f3e5}',
  hot_face: '\u{1f975}',
  hot_pepper: '\u{1f336}',
  hotdog: '\u{1f32d}',
  hotel: '\u{1f3e8}',
  hotsprings: '\u{2668}',
  hourglass: '\u{231b}',
  hourglass_flowing_sand: '\u{23f3}',
  house: '\u{1f3e0}',
  house_with_garden: '\u{1f3e1}',
  houses: '\u{1f3d8}',
  hugs: '\u{1f917}',
  hungary: '\u{1f1ed}\u{200d}\u{1f1fa}',
  hushed: '\u{1f62f}',
  ice_cream: '\u{1f368}',
  ice_cube: '\u{1f9ca}',
  ice_hockey: '\u{1f3d2}',
  ice_skate: '\u{26f8}',
  icecream: '\u{1f366}',
  iceland: '\u{1f1ee}\u{200d}\u{1f1f8}',
  id: '\u{1f194}',
  ideograph_advantage: '\u{1f250}',
  imp: '\u{1f47f}',
  inbox_tray: '\u{1f4e5}',
  incoming_envelope: '\u{1f4e8}',
  india: '\u{1f1ee}\u{200d}\u{1f1f3}',
  indonesia: '\u{1f1ee}\u{200d}\u{1f1e9}',
  infinity: '\u{267e}',
  information_desk_person: '\u{1f481}',
  information_source: '\u{2139}',
  innocent: '\u{1f607}',
  interrobang: '\u{2049}',
  iphone: '\u{1f4f1}',
  iran: '\u{1f1ee}\u{200d}\u{1f1f7}',
  iraq: '\u{1f1ee}\u{200d}\u{1f1f6}',
  ireland: '\u{1f1ee}\u{200d}\u{1f1ea}',
  isle_of_man: '\u{1f1ee}\u{200d}\u{1f1f2}',
  israel: '\u{1f1ee}\u{200d}\u{1f1f1}',
  it: '\u{1f1ee}\u{200d}\u{1f1f9}',
  izakaya_lantern: '\u{1f3ee}',
  jack_o_lantern: '\u{1f383}',
  jamaica: '\u{1f1ef}\u{200d}\u{1f1f2}',
  japan: '\u{1f5fe}',
  japanese_castle: '\u{1f3ef}',
  japanese_goblin: '\u{1f47a}',
  japanese_ogre: '\u{1f479}',
  jeans: '\u{1f456}',
  jersey: '\u{1f1ef}\u{200d}\u{1f1ea}',
  jigsaw: '\u{1f9e9}',
  jordan: '\u{1f1ef}\u{200d}\u{1f1f4}',
  joy: '\u{1f602}',
  joy_cat: '\u{1f639}',
  joystick: '\u{1f579}',
  jp: '\u{1f1ef}\u{200d}\u{1f1f5}',
  judge: '\u{1f9d1}\u{200d}\u{2696}',
  juggling_person: '\u{1f939}',
  kaaba: '\u{1f54b}',
  kangaroo: '\u{1f998}',
  kazakhstan: '\u{1f1f0}\u{200d}\u{1f1ff}',
  kenya: '\u{1f1f0}\u{200d}\u{1f1ea}',
  key: '\u{1f511}',
  keyboard: '\u{2328}',
  keycap_ten: '\u{1f51f}',
  kick_scooter: '\u{1f6f4}',
  kimono: '\u{1f458}',
  kiribati: '\u{1f1f0}\u{200d}\u{1f1ee}',
  kiss: '\u{1f48b}',
  kissing: '\u{1f617}',
  kissing_cat: '\u{1f63d}',
  kissing_closed_eyes: '\u{1f61a}',
  kissing_heart: '\u{1f618}',
  kissing_smiling_eyes: '\u{1f619}',
  kite: '\u{1fa81}',
  kiwi_fruit: '\u{1f95d}',
  kneeling_man: '\u{1f9ce}\u{200d}\u{2642}',
  kneeling_person: '\u{1f9ce}',
  kneeling_woman: '\u{1f9ce}\u{200d}\u{2640}',
  knife: '\u{1f52a}',
  koala: '\u{1f428}',
  koko: '\u{1f201}',
  kosovo: '\u{1f1fd}\u{200d}\u{1f1f0}',
  kr: '\u{1f1f0}\u{200d}\u{1f1f7}',
  kuwait: '\u{1f1f0}\u{200d}\u{1f1fc}',
  kyrgyzstan: '\u{1f1f0}\u{200d}\u{1f1ec}',
  lab_coat: '\u{1f97c}',
  label: '\u{1f3f7}',
  lacrosse: '\u{1f94d}',
  lantern: '\u{1f3ee}',
  laos: '\u{1f1f1}\u{200d}\u{1f1e6}',
  large_blue_circle: '\u{1f535}',
  large_blue_diamond: '\u{1f537}',
  large_orange_diamond: '\u{1f536}',
  last_quarter_moon: '\u{1f317}',
  last_quarter_moon_with_face: '\u{1f31c}',
  latin_cross: '\u{271d}',
  latvia: '\u{1f1f1}\u{200d}\u{1f1fb}',
  laughing: '\u{1f606}',
  leafy_green: '\u{1f96c}',
  leaves: '\u{1f343}',
  lebanon: '\u{1f1f1}\u{200d}\u{1f1e7}',
  ledger: '\u{1f4d2}',
  left_luggage: '\u{1f6c5}',
  left_right_arrow: '\u{2194}',
  left_speech_bubble: '\u{1f5e8}',
  leftwards_arrow_with_hook: '\u{21a9}',
  leg: '\u{1f9b5}',
  lemon: '\u{1f34b}',
  leo: '\u{264c}',
  leopard: '\u{1f406}',
  lesotho: '\u{1f1f1}\u{200d}\u{1f1f8}',
  level_slider: '\u{1f39a}',
  liberia: '\u{1f1f1}\u{200d}\u{1f1f7}',
  libra: '\u{264e}',
  libya: '\u{1f1f1}\u{200d}\u{1f1fe}',
  liechtenstein: '\u{1f1f1}\u{200d}\u{1f1ee}',
  light_rail: '\u{1f688}',
  link: '\u{1f517}',
  lion: '\u{1f981}',
  lips: '\u{1f444}',
  lipstick: '\u{1f484}',
  lithuania: '\u{1f1f1}\u{200d}\u{1f1f9}',
  lizard: '\u{1f98e}',
  llama: '\u{1f999}',
  lobster: '\u{1f99e}',
  lock: '\u{1f512}',
  lock_with_ink_pen: '\u{1f50f}',
  lollipop: '\u{1f36d}',
  loop: '\u{27bf}',
  lotion_bottle: '\u{1f9f4}',
  lotus_position: '\u{1f9d8}',
  lotus_position_man: '\u{1f9d8}\u{200d}\u{2642}',
  lotus_position_woman: '\u{1f9d8}\u{200d}\u{2640}',
  loud_sound: '\u{1f50a}',
  loudspeaker: '\u{1f4e2}',
  love_hotel: '\u{1f3e9}',
  love_letter: '\u{1f48c}',
  love_you_gesture: '\u{1f91f}',
  low_brightness: '\u{1f505}',
  luggage: '\u{1f9f3}',
  luxembourg: '\u{1f1f1}\u{200d}\u{1f1fa}',
  lying_face: '\u{1f925}',
  m: '\u{24c2}',
  macau: '\u{1f1f2}\u{200d}\u{1f1f4}',
  macedonia: '\u{1f1f2}\u{200d}\u{1f1f0}',
  madagascar: '\u{1f1f2}\u{200d}\u{1f1ec}',
  mag: '\u{1f50d}',
  mag_right: '\u{1f50e}',
  mage: '\u{1f9d9}',
  mage_man: '\u{1f9d9}\u{200d}\u{2642}',
  mage_woman: '\u{1f9d9}\u{200d}\u{2640}',
  magnet: '\u{1f9f2}',
  mahjong: '\u{1f004}',
  mailbox: '\u{1f4eb}',
  mailbox_closed: '\u{1f4ea}',
  mailbox_with_mail: '\u{1f4ec}',
  mailbox_with_no_mail: '\u{1f4ed}',
  malawi: '\u{1f1f2}\u{200d}\u{1f1fc}',
  malaysia: '\u{1f1f2}\u{200d}\u{1f1fe}',
  maldives: '\u{1f1f2}\u{200d}\u{1f1fb}',
  male_detective: '\u{1f575}\u{200d}\u{2642}',
  male_sign: '\u{2642}',
  mali: '\u{1f1f2}\u{200d}\u{1f1f1}',
  malta: '\u{1f1f2}\u{200d}\u{1f1f9}',
  man: '\u{1f468}',
  man_artist: '\u{1f468}\u{200d}\u{1f3a8}',
  man_astronaut: '\u{1f468}\u{200d}\u{1f680}',
  man_cartwheeling: '\u{1f938}\u{200d}\u{2642}',
  man_cook: '\u{1f468}\u{200d}\u{1f373}',
  man_dancing: '\u{1f57a}',
  man_facepalming: '\u{1f926}\u{200d}\u{2642}',
  man_factory_worker: '\u{1f468}\u{200d}\u{1f3ed}',
  man_farmer: '\u{1f468}\u{200d}\u{1f33e}',
  man_firefighter: '\u{1f468}\u{200d}\u{1f692}',
  man_health_worker: '\u{1f468}\u{200d}\u{2695}',
  man_in_manual_wheelchair: '\u{1f468}\u{200d}\u{1f9bd}',
  man_in_motorized_wheelchair: '\u{1f468}\u{200d}\u{1f9bc}',
  man_in_tuxedo: '\u{1f935}',
  man_judge: '\u{1f468}\u{200d}\u{2696}',
  man_juggling: '\u{1f939}\u{200d}\u{2642}',
  man_mechanic: '\u{1f468}\u{200d}\u{1f527}',
  man_office_worker: '\u{1f468}\u{200d}\u{1f4bc}',
  man_pilot: '\u{1f468}\u{200d}\u{2708}',
  man_playing_handball: '\u{1f93e}\u{200d}\u{2642}',
  man_playing_water_polo: '\u{1f93d}\u{200d}\u{2642}',
  man_scientist: '\u{1f468}\u{200d}\u{1f52c}',
  man_shrugging: '\u{1f937}\u{200d}\u{2642}',
  man_singer: '\u{1f468}\u{200d}\u{1f3a4}',
  man_student: '\u{1f468}\u{200d}\u{1f393}',
  man_teacher: '\u{1f468}\u{200d}\u{1f3eb}',
  man_technologist: '\u{1f468}\u{200d}\u{1f4bb}',
  man_with_gua_pi_mao: '\u{1f472}',
  man_with_probing_cane: '\u{1f468}\u{200d}\u{1f9af}',
  man_with_turban: '\u{1f473}\u{200d}\u{2642}',
  mandarin: '\u{1f34a}',
  mango: '\u{1f96d}',
  mans_shoe: '\u{1f45e}',
  mantelpiece_clock: '\u{1f570}',
  manual_wheelchair: '\u{1f9bd}',
  maple_leaf: '\u{1f341}',
  marshall_islands: '\u{1f1f2}\u{200d}\u{1f1ed}',
  martial_arts_uniform: '\u{1f94b}',
  martinique: '\u{1f1f2}\u{200d}\u{1f1f6}',
  mask: '\u{1f637}',
  massage: '\u{1f486}',
  massage_man: '\u{1f486}\u{200d}\u{2642}',
  massage_woman: '\u{1f486}\u{200d}\u{2640}',
  mate: '\u{1f9c9}',
  mauritania: '\u{1f1f2}\u{200d}\u{1f1f7}',
  mauritius: '\u{1f1f2}\u{200d}\u{1f1fa}',
  mayotte: '\u{1f1fe}\u{200d}\u{1f1f9}',
  meat_on_bone: '\u{1f356}',
  mechanic: '\u{1f9d1}\u{200d}\u{1f527}',
  mechanical_arm: '\u{1f9be}',
  mechanical_leg: '\u{1f9bf}',
  medal_military: '\u{1f396}',
  medal_sports: '\u{1f3c5}',
  medical_symbol: '\u{2695}',
  mega: '\u{1f4e3}',
  melon: '\u{1f348}',
  memo: '\u{1f4dd}',
  men_wrestling: '\u{1f93c}\u{200d}\u{2642}',
  menorah: '\u{1f54e}',
  mens: '\u{1f6b9}',
  mermaid: '\u{1f9dc}\u{200d}\u{2640}',
  merman: '\u{1f9dc}\u{200d}\u{2642}',
  merperson: '\u{1f9dc}',
  metal: '\u{1f918}',
  metro: '\u{1f687}',
  mexico: '\u{1f1f2}\u{200d}\u{1f1fd}',
  microbe: '\u{1f9a0}',
  micronesia: '\u{1f1eb}\u{200d}\u{1f1f2}',
  microphone: '\u{1f3a4}',
  microscope: '\u{1f52c}',
  middle_finger: '\u{1f595}',
  milk_glass: '\u{1f95b}',
  milky_way: '\u{1f30c}',
  minibus: '\u{1f690}',
  minidisc: '\u{1f4bd}',
  mobile_phone_off: '\u{1f4f4}',
  moldova: '\u{1f1f2}\u{200d}\u{1f1e9}',
  monaco: '\u{1f1f2}\u{200d}\u{1f1e8}',
  money_mouth_face: '\u{1f911}',
  money_with_wings: '\u{1f4b8}',
  moneybag: '\u{1f4b0}',
  mongolia: '\u{1f1f2}\u{200d}\u{1f1f3}',
  monkey: '\u{1f412}',
  monkey_face: '\u{1f435}',
  monocle_face: '\u{1f9d0}',
  monorail: '\u{1f69d}',
  montenegro: '\u{1f1f2}\u{200d}\u{1f1ea}',
  montserrat: '\u{1f1f2}\u{200d}\u{1f1f8}',
  moon: '\u{1f314}',
  moon_cake: '\u{1f96e}',
  morocco: '\u{1f1f2}\u{200d}\u{1f1e6}',
  mortar_board: '\u{1f393}',
  mosque: '\u{1f54c}',
  mosquito: '\u{1f99f}',
  motor_boat: '\u{1f6e5}',
  motor_scooter: '\u{1f6f5}',
  motorcycle: '\u{1f3cd}',
  motorized_wheelchair: '\u{1f9bc}',
  motorway: '\u{1f6e3}',
  mount_fuji: '\u{1f5fb}',
  mountain: '\u{26f0}',
  mountain_bicyclist: '\u{1f6b5}',
  mountain_biking_man: '\u{1f6b5}\u{200d}\u{2642}',
  mountain_biking_woman: '\u{1f6b5}\u{200d}\u{2640}',
  mountain_cableway: '\u{1f6a0}',
  mountain_railway: '\u{1f69e}',
  mountain_snow: '\u{1f3d4}',
  mouse: '\u{1f42d}',
  mouse2: '\u{1f401}',
  movie_camera: '\u{1f3a5}',
  moyai: '\u{1f5ff}',
  mozambique: '\u{1f1f2}\u{200d}\u{1f1ff}',
  mrs_claus: '\u{1f936}',
  muscle: '\u{1f4aa}',
  mushroom: '\u{1f344}',
  musical_keyboard: '\u{1f3b9}',
  musical_note: '\u{1f3b5}',
  musical_score: '\u{1f3bc}',
  mute: '\u{1f507}',
  myanmar: '\u{1f1f2}\u{200d}\u{1f1f2}',
  nail_care: '\u{1f485}',
  name_badge: '\u{1f4db}',
  namibia: '\u{1f1f3}\u{200d}\u{1f1e6}',
  national_park: '\u{1f3de}',
  nauru: '\u{1f1f3}\u{200d}\u{1f1f7}',
  nauseated_face: '\u{1f922}',
  nazar_amulet: '\u{1f9ff}',
  necktie: '\u{1f454}',
  negative_squared_cross_mark: '\u{274e}',
  nepal: '\u{1f1f3}\u{200d}\u{1f1f5}',
  nerd_face: '\u{1f913}',
  netherlands: '\u{1f1f3}\u{200d}\u{1f1f1}',
  neutral_face: '\u{1f610}',
  new: '\u{1f195}',
  new_caledonia: '\u{1f1f3}\u{200d}\u{1f1e8}',
  new_moon: '\u{1f311}',
  new_moon_with_face: '\u{1f31a}',
  new_zealand: '\u{1f1f3}\u{200d}\u{1f1ff}',
  newspaper: '\u{1f4f0}',
  newspaper_roll: '\u{1f5de}',
  next_track_button: '\u{23ed}',
  ng: '\u{1f196}',
  ng_man: '\u{1f645}\u{200d}\u{2642}',
  ng_woman: '\u{1f645}\u{200d}\u{2640}',
  nicaragua: '\u{1f1f3}\u{200d}\u{1f1ee}',
  niger: '\u{1f1f3}\u{200d}\u{1f1ea}',
  nigeria: '\u{1f1f3}\u{200d}\u{1f1ec}',
  night_with_stars: '\u{1f303}',
  nine: '\u{0039}\u{200d}\u{20e3}',
  niue: '\u{1f1f3}\u{200d}\u{1f1fa}',
  no_bell: '\u{1f515}',
  no_bicycles: '\u{1f6b3}',
  no_entry: '\u{26d4}',
  no_entry_sign: '\u{1f6ab}',
  no_good: '\u{1f645}',
  no_good_man: '\u{1f645}\u{200d}\u{2642}',
  no_good_woman: '\u{1f645}\u{200d}\u{2640}',
  no_mobile_phones: '\u{1f4f5}',
  no_mouth: '\u{1f636}',
  no_pedestrians: '\u{1f6b7}',
  no_smoking: '\u{1f6ad}',
  'non-potable_water': '\u{1f6b1}',
  norfolk_island: '\u{1f1f3}\u{200d}\u{1f1eb}',
  north_korea: '\u{1f1f0}\u{200d}\u{1f1f5}',
  northern_mariana_islands: '\u{1f1f2}\u{200d}\u{1f1f5}',
  norway: '\u{1f1f3}\u{200d}\u{1f1f4}',
  nose: '\u{1f443}',
  notebook: '\u{1f4d3}',
  notebook_with_decorative_cover: '\u{1f4d4}',
  notes: '\u{1f3b6}',
  nut_and_bolt: '\u{1f529}',
  o: '\u{2b55}',
  o2: '\u{1f17e}',
  ocean: '\u{1f30a}',
  octopus: '\u{1f419}',
  oden: '\u{1f362}',
  office: '\u{1f3e2}',
  office_worker: '\u{1f9d1}\u{200d}\u{1f4bc}',
  oil_drum: '\u{1f6e2}',
  ok: '\u{1f197}',
  ok_hand: '\u{1f44c}',
  ok_man: '\u{1f646}\u{200d}\u{2642}',
  ok_person: '\u{1f646}',
  ok_woman: '\u{1f646}\u{200d}\u{2640}',
  old_key: '\u{1f5dd}',
  older_adult: '\u{1f9d3}',
  older_man: '\u{1f474}',
  older_woman: '\u{1f475}',
  om: '\u{1f549}',
  oman: '\u{1f1f4}\u{200d}\u{1f1f2}',
  on: '\u{1f51b}',
  oncoming_automobile: '\u{1f698}',
  oncoming_bus: '\u{1f68d}',
  oncoming_police_car: '\u{1f694}',
  oncoming_taxi: '\u{1f696}',
  one: '\u{0031}\u{200d}\u{20e3}',
  one_piece_swimsuit: '\u{1fa71}',
  onion: '\u{1f9c5}',
  open_book: '\u{1f4d6}',
  open_file_folder: '\u{1f4c2}',
  open_hands: '\u{1f450}',
  open_mouth: '\u{1f62e}',
  open_umbrella: '\u{2602}',
  ophiuchus: '\u{26ce}',
  orange: '\u{1f34a}',
  orange_book: '\u{1f4d9}',
  orange_circle: '\u{1f7e0}',
  orange_heart: '\u{1f9e1}',
  orange_square: '\u{1f7e7}',
  orangutan: '\u{1f9a7}',
  orthodox_cross: '\u{2626}',
  otter: '\u{1f9a6}',
  outbox_tray: '\u{1f4e4}',
  owl: '\u{1f989}',
  ox: '\u{1f402}',
  oyster: '\u{1f9aa}',
  package: '\u{1f4e6}',
  page_facing_up: '\u{1f4c4}',
  page_with_curl: '\u{1f4c3}',
  pager: '\u{1f4df}',
  paintbrush: '\u{1f58c}',
  pakistan: '\u{1f1f5}\u{200d}\u{1f1f0}',
  palau: '\u{1f1f5}\u{200d}\u{1f1fc}',
  palestinian_territories: '\u{1f1f5}\u{200d}\u{1f1f8}',
  palm_tree: '\u{1f334}',
  palms_up_together: '\u{1f932}',
  panama: '\u{1f1f5}\u{200d}\u{1f1e6}',
  pancakes: '\u{1f95e}',
  panda_face: '\u{1f43c}',
  paperclip: '\u{1f4ce}',
  paperclips: '\u{1f587}',
  papua_new_guinea: '\u{1f1f5}\u{200d}\u{1f1ec}',
  parachute: '\u{1fa82}',
  paraguay: '\u{1f1f5}\u{200d}\u{1f1fe}',
  parasol_on_ground: '\u{26f1}',
  parking: '\u{1f17f}',
  parrot: '\u{1f99c}',
  part_alternation_mark: '\u{303d}',
  partly_sunny: '\u{26c5}',
  partying_face: '\u{1f973}',
  passenger_ship: '\u{1f6f3}',
  passport_control: '\u{1f6c2}',
  pause_button: '\u{23f8}',
  paw_prints: '\u{1f43e}',
  peace_symbol: '\u{262e}',
  peach: '\u{1f351}',
  peacock: '\u{1f99a}',
  peanuts: '\u{1f95c}',
  pear: '\u{1f350}',
  pen: '\u{1f58a}',
  pencil: '\u{1f4dd}',
  pencil2: '\u{270f}',
  penguin: '\u{1f427}',
  pensive: '\u{1f614}',
  people_holding_hands: '\u{1f9d1}\u{200d}\u{1f91d}\u{200d}\u{1f9d1}',
  performing_arts: '\u{1f3ad}',
  persevere: '\u{1f623}',
  person_bald: '\u{1f9d1}\u{200d}\u{1f9b2}',
  person_curly_hair: '\u{1f9d1}\u{200d}\u{1f9b1}',
  person_fencing: '\u{1f93a}',
  person_in_manual_wheelchair: '\u{1f9d1}\u{200d}\u{1f9bd}',
  person_in_motorized_wheelchair: '\u{1f9d1}\u{200d}\u{1f9bc}',
  person_red_hair: '\u{1f9d1}\u{200d}\u{1f9b0}',
  person_white_hair: '\u{1f9d1}\u{200d}\u{1f9b3}',
  person_with_probing_cane: '\u{1f9d1}\u{200d}\u{1f9af}',
  person_with_turban: '\u{1f473}',
  peru: '\u{1f1f5}\u{200d}\u{1f1ea}',
  petri_dish: '\u{1f9eb}',
  philippines: '\u{1f1f5}\u{200d}\u{1f1ed}',
  phone: '\u{260e}',
  pick: '\u{26cf}',
  pie: '\u{1f967}',
  pig: '\u{1f437}',
  pig2: '\u{1f416}',
  pig_nose: '\u{1f43d}',
  pill: '\u{1f48a}',
  pilot: '\u{1f9d1}\u{200d}\u{2708}',
  pinching_hand: '\u{1f90f}',
  pineapple: '\u{1f34d}',
  ping_pong: '\u{1f3d3}',
  pirate_flag: '\u{1f3f4}\u{200d}\u{2620}',
  pisces: '\u{2653}',
  pitcairn_islands: '\u{1f1f5}\u{200d}\u{1f1f3}',
  pizza: '\u{1f355}',
  place_of_worship: '\u{1f6d0}',
  plate_with_cutlery: '\u{1f37d}',
  play_or_pause_button: '\u{23ef}',
  pleading_face: '\u{1f97a}',
  point_down: '\u{1f447}',
  point_left: '\u{1f448}',
  point_right: '\u{1f449}',
  point_up: '\u{261d}',
  point_up_2: '\u{1f446}',
  poland: '\u{1f1f5}\u{200d}\u{1f1f1}',
  police_car: '\u{1f693}',
  police_officer: '\u{1f46e}',
  policeman: '\u{1f46e}\u{200d}\u{2642}',
  policewoman: '\u{1f46e}\u{200d}\u{2640}',
  poodle: '\u{1f429}',
  poop: '\u{1f4a9}',
  popcorn: '\u{1f37f}',
  portugal: '\u{1f1f5}\u{200d}\u{1f1f9}',
  post_office: '\u{1f3e3}',
  postal_horn: '\u{1f4ef}',
  postbox: '\u{1f4ee}',
  potable_water: '\u{1f6b0}',
  potato: '\u{1f954}',
  pouch: '\u{1f45d}',
  poultry_leg: '\u{1f357}',
  pound: '\u{1f4b7}',
  pout: '\u{1f621}',
  pouting_cat: '\u{1f63e}',
  pouting_face: '\u{1f64e}',
  pouting_man: '\u{1f64e}\u{200d}\u{2642}',
  pouting_woman: '\u{1f64e}\u{200d}\u{2640}',
  pray: '\u{1f64f}',
  prayer_beads: '\u{1f4ff}',
  pregnant_woman: '\u{1f930}',
  pretzel: '\u{1f968}',
  previous_track_button: '\u{23ee}',
  prince: '\u{1f934}',
  princess: '\u{1f478}',
  printer: '\u{1f5a8}',
  probing_cane: '\u{1f9af}',
  puerto_rico: '\u{1f1f5}\u{200d}\u{1f1f7}',
  punch: '\u{1f44a}',
  purple_circle: '\u{1f7e3}',
  purple_heart: '\u{1f49c}',
  purple_square: '\u{1f7ea}',
  purse: '\u{1f45b}',
  pushpin: '\u{1f4cc}',
  put_litter_in_its_place: '\u{1f6ae}',
  qatar: '\u{1f1f6}\u{200d}\u{1f1e6}',
  question: '\u{2753}',
  rabbit: '\u{1f430}',
  rabbit2: '\u{1f407}',
  raccoon: '\u{1f99d}',
  racehorse: '\u{1f40e}',
  racing_car: '\u{1f3ce}',
  radio: '\u{1f4fb}',
  radio_button: '\u{1f518}',
  radioactive: '\u{2622}',
  rage: '\u{1f621}',
  railway_car: '\u{1f683}',
  railway_track: '\u{1f6e4}',
  rainbow: '\u{1f308}',
  rainbow_flag: '\u{1f3f3}\u{200d}\u{1f308}',
  raised_back_of_hand: '\u{1f91a}',
  raised_eyebrow: '\u{1f928}',
  raised_hand: '\u{270b}',
  raised_hand_with_fingers_splayed: '\u{1f590}',
  raised_hands: '\u{1f64c}',
  raising_hand: '\u{1f64b}',
  raising_hand_man: '\u{1f64b}\u{200d}\u{2642}',
  raising_hand_woman: '\u{1f64b}\u{200d}\u{2640}',
  ram: '\u{1f40f}',
  ramen: '\u{1f35c}',
  rat: '\u{1f400}',
  razor: '\u{1fa92}',
  receipt: '\u{1f9fe}',
  record_button: '\u{23fa}',
  recycle: '\u{267b}',
  red_car: '\u{1f697}',
  red_circle: '\u{1f534}',
  red_envelope: '\u{1f9e7}',
  red_haired_man: '\u{1f468}\u{200d}\u{1f9b0}',
  red_haired_woman: '\u{1f469}\u{200d}\u{1f9b0}',
  red_square: '\u{1f7e5}',
  registered: '\u{00ae}',
  relaxed: '\u{263a}',
  relieved: '\u{1f60c}',
  reminder_ribbon: '\u{1f397}',
  repeat: '\u{1f501}',
  repeat_one: '\u{1f502}',
  rescue_worker_helmet: '\u{26d1}',
  restroom: '\u{1f6bb}',
  reunion: '\u{1f1f7}\u{200d}\u{1f1ea}',
  revolving_hearts: '\u{1f49e}',
  rewind: '\u{23ea}',
  rhinoceros: '\u{1f98f}',
  ribbon: '\u{1f380}',
  rice: '\u{1f35a}',
  rice_ball: '\u{1f359}',
  rice_cracker: '\u{1f358}',
  rice_scene: '\u{1f391}',
  right_anger_bubble: '\u{1f5ef}',
  ring: '\u{1f48d}',
  ringed_planet: '\u{1fa90}',
  robot: '\u{1f916}',
  rocket: '\u{1f680}',
  rofl: '\u{1f923}',
  roll_eyes: '\u{1f644}',
  roll_of_paper: '\u{1f9fb}',
  roller_coaster: '\u{1f3a2}',
  romania: '\u{1f1f7}\u{200d}\u{1f1f4}',
  rooster: '\u{1f413}',
  rose: '\u{1f339}',
  rosette: '\u{1f3f5}',
  rotating_light: '\u{1f6a8}',
  round_pushpin: '\u{1f4cd}',
  rowboat: '\u{1f6a3}',
  rowing_man: '\u{1f6a3}\u{200d}\u{2642}',
  rowing_woman: '\u{1f6a3}\u{200d}\u{2640}',
  ru: '\u{1f1f7}\u{200d}\u{1f1fa}',
  rugby_football: '\u{1f3c9}',
  runner: '\u{1f3c3}',
  running: '\u{1f3c3}',
  running_man: '\u{1f3c3}\u{200d}\u{2642}',
  running_shirt_with_sash: '\u{1f3bd}',
  running_woman: '\u{1f3c3}\u{200d}\u{2640}',
  rwanda: '\u{1f1f7}\u{200d}\u{1f1fc}',
  sa: '\u{1f202}',
  safety_pin: '\u{1f9f7}',
  safety_vest: '\u{1f9ba}',
  sagittarius: '\u{2650}',
  sailboat: '\u{26f5}',
  sake: '\u{1f376}',
  salt: '\u{1f9c2}',
  samoa: '\u{1f1fc}\u{200d}\u{1f1f8}',
  san_marino: '\u{1f1f8}\u{200d}\u{1f1f2}',
  sandal: '\u{1f461}',
  sandwich: '\u{1f96a}',
  santa: '\u{1f385}',
  sao_tome_principe: '\u{1f1f8}\u{200d}\u{1f1f9}',
  sari: '\u{1f97b}',
  sassy_man: '\u{1f481}\u{200d}\u{2642}',
  sassy_woman: '\u{1f481}\u{200d}\u{2640}',
  satellite: '\u{1f4e1}',
  satisfied: '\u{1f606}',
  saudi_arabia: '\u{1f1f8}\u{200d}\u{1f1e6}',
  sauna_man: '\u{1f9d6}\u{200d}\u{2642}',
  sauna_person: '\u{1f9d6}',
  sauna_woman: '\u{1f9d6}\u{200d}\u{2640}',
  sauropod: '\u{1f995}',
  saxophone: '\u{1f3b7}',
  scarf: '\u{1f9e3}',
  school: '\u{1f3eb}',
  school_satchel: '\u{1f392}',
  scientist: '\u{1f9d1}\u{200d}\u{1f52c}',
  scissors: '\u{2702}',
  scorpion: '\u{1f982}',
  scorpius: '\u{264f}',
  scotland:
    '\u{1f3f4}\u{200d}\u{e0067}\u{200d}\u{e0062}\u{200d}\u{e0073}\u{200d}\u{e0063}\u{200d}\u{e0074}\u{200d}\u{e007f}',
  scream: '\u{1f631}',
  scream_cat: '\u{1f640}',
  scroll: '\u{1f4dc}',
  seat: '\u{1f4ba}',
  secret: '\u{3299}',
  see_no_evil: '\u{1f648}',
  seedling: '\u{1f331}',
  selfie: '\u{1f933}',
  senegal: '\u{1f1f8}\u{200d}\u{1f1f3}',
  serbia: '\u{1f1f7}\u{200d}\u{1f1f8}',
  service_dog: '\u{1f415}\u{200d}\u{1f9ba}',
  seven: '\u{0037}\u{200d}\u{20e3}',
  seychelles: '\u{1f1f8}\u{200d}\u{1f1e8}',
  shallow_pan_of_food: '\u{1f958}',
  shamrock: '\u{2618}',
  shark: '\u{1f988}',
  shaved_ice: '\u{1f367}',
  sheep: '\u{1f411}',
  shell: '\u{1f41a}',
  shield: '\u{1f6e1}',
  shinto_shrine: '\u{26e9}',
  ship: '\u{1f6a2}',
  shirt: '\u{1f455}',
  shoe: '\u{1f45e}',
  shopping: '\u{1f6cd}',
  shopping_cart: '\u{1f6d2}',
  shorts: '\u{1fa73}',
  shower: '\u{1f6bf}',
  shrimp: '\u{1f990}',
  shrug: '\u{1f937}',
  shushing_face: '\u{1f92b}',
  sierra_leone: '\u{1f1f8}\u{200d}\u{1f1f1}',
  signal_strength: '\u{1f4f6}',
  singapore: '\u{1f1f8}\u{200d}\u{1f1ec}',
  singer: '\u{1f9d1}\u{200d}\u{1f3a4}',
  sint_maarten: '\u{1f1f8}\u{200d}\u{1f1fd}',
  six: '\u{0036}\u{200d}\u{20e3}',
  six_pointed_star: '\u{1f52f}',
  skateboard: '\u{1f6f9}',
  ski: '\u{1f3bf}',
  skier: '\u{26f7}',
  skull: '\u{1f480}',
  skull_and_crossbones: '\u{2620}',
  skunk: '\u{1f9a8}',
  sled: '\u{1f6f7}',
  sleeping: '\u{1f634}',
  sleeping_bed: '\u{1f6cc}',
  sleepy: '\u{1f62a}',
  slightly_frowning_face: '\u{1f641}',
  slightly_smiling_face: '\u{1f642}',
  slot_machine: '\u{1f3b0}',
  sloth: '\u{1f9a5}',
  slovakia: '\u{1f1f8}\u{200d}\u{1f1f0}',
  slovenia: '\u{1f1f8}\u{200d}\u{1f1ee}',
  small_airplane: '\u{1f6e9}',
  small_blue_diamond: '\u{1f539}',
  small_orange_diamond: '\u{1f538}',
  small_red_triangle: '\u{1f53a}',
  small_red_triangle_down: '\u{1f53b}',
  smile: '\u{1f604}',
  smile_cat: '\u{1f638}',
  smiley: '\u{1f603}',
  smiley_cat: '\u{1f63a}',
  smiling_face_with_three_hearts: '\u{1f970}',
  smiling_imp: '\u{1f608}',
  smirk: '\u{1f60f}',
  smirk_cat: '\u{1f63c}',
  smoking: '\u{1f6ac}',
  snail: '\u{1f40c}',
  snake: '\u{1f40d}',
  sneezing_face: '\u{1f927}',
  snowboarder: '\u{1f3c2}',
  snowflake: '\u{2744}',
  snowman: '\u{26c4}',
  snowman_with_snow: '\u{2603}',
  soap: '\u{1f9fc}',
  sob: '\u{1f62d}',
  soccer: '\u{26bd}',
  socks: '\u{1f9e6}',
  softball: '\u{1f94e}',
  solomon_islands: '\u{1f1f8}\u{200d}\u{1f1e7}',
  somalia: '\u{1f1f8}\u{200d}\u{1f1f4}',
  soon: '\u{1f51c}',
  sos: '\u{1f198}',
  sound: '\u{1f509}',
  south_africa: '\u{1f1ff}\u{200d}\u{1f1e6}',
  south_georgia_south_sandwich_islands: '\u{1f1ec}\u{200d}\u{1f1f8}',
  south_sudan: '\u{1f1f8}\u{200d}\u{1f1f8}',
  space_invader: '\u{1f47e}',
  spades: '\u{2660}',
  spaghetti: '\u{1f35d}',
  sparkle: '\u{2747}',
  sparkler: '\u{1f387}',
  sparkles: '\u{2728}',
  sparkling_heart: '\u{1f496}',
  speak_no_evil: '\u{1f64a}',
  speaker: '\u{1f508}',
  speaking_head: '\u{1f5e3}',
  speech_balloon: '\u{1f4ac}',
  speedboat: '\u{1f6a4}',
  spider: '\u{1f577}',
  spider_web: '\u{1f578}',
  spiral_calendar: '\u{1f5d3}',
  spiral_notepad: '\u{1f5d2}',
  sponge: '\u{1f9fd}',
  spoon: '\u{1f944}',
  squid: '\u{1f991}',
  sri_lanka: '\u{1f1f1}\u{200d}\u{1f1f0}',
  st_barthelemy: '\u{1f1e7}\u{200d}\u{1f1f1}',
  st_helena: '\u{1f1f8}\u{200d}\u{1f1ed}',
  st_kitts_nevis: '\u{1f1f0}\u{200d}\u{1f1f3}',
  st_lucia: '\u{1f1f1}\u{200d}\u{1f1e8}',
  st_martin: '\u{1f1f2}\u{200d}\u{1f1eb}',
  st_pierre_miquelon: '\u{1f1f5}\u{200d}\u{1f1f2}',
  st_vincent_grenadines: '\u{1f1fb}\u{200d}\u{1f1e8}',
  stadium: '\u{1f3df}',
  standing_man: '\u{1f9cd}\u{200d}\u{2642}',
  standing_person: '\u{1f9cd}',
  standing_woman: '\u{1f9cd}\u{200d}\u{2640}',
  star: '\u{2b50}',
  star2: '\u{1f31f}',
  star_and_crescent: '\u{262a}',
  star_of_david: '\u{2721}',
  star_struck: '\u{1f929}',
  stars: '\u{1f320}',
  station: '\u{1f689}',
  statue_of_liberty: '\u{1f5fd}',
  steam_locomotive: '\u{1f682}',
  stethoscope: '\u{1fa7a}',
  stew: '\u{1f372}',
  stop_button: '\u{23f9}',
  stop_sign: '\u{1f6d1}',
  stopwatch: '\u{23f1}',
  straight_ruler: '\u{1f4cf}',
  strawberry: '\u{1f353}',
  stuck_out_tongue: '\u{1f61b}',
  stuck_out_tongue_closed_eyes: '\u{1f61d}',
  stuck_out_tongue_winking_eye: '\u{1f61c}',
  student: '\u{1f9d1}\u{200d}\u{1f393}',
  studio_microphone: '\u{1f399}',
  stuffed_flatbread: '\u{1f959}',
  sudan: '\u{1f1f8}\u{200d}\u{1f1e9}',
  sun_behind_large_cloud: '\u{1f325}',
  sun_behind_rain_cloud: '\u{1f326}',
  sun_behind_small_cloud: '\u{1f324}',
  sun_with_face: '\u{1f31e}',
  sunflower: '\u{1f33b}',
  sunglasses: '\u{1f60e}',
  sunny: '\u{2600}',
  sunrise: '\u{1f305}',
  sunrise_over_mountains: '\u{1f304}',
  superhero: '\u{1f9b8}',
  superhero_man: '\u{1f9b8}\u{200d}\u{2642}',
  superhero_woman: '\u{1f9b8}\u{200d}\u{2640}',
  supervillain: '\u{1f9b9}',
  supervillain_man: '\u{1f9b9}\u{200d}\u{2642}',
  supervillain_woman: '\u{1f9b9}\u{200d}\u{2640}',
  surfer: '\u{1f3c4}',
  surfing_man: '\u{1f3c4}\u{200d}\u{2642}',
  surfing_woman: '\u{1f3c4}\u{200d}\u{2640}',
  suriname: '\u{1f1f8}\u{200d}\u{1f1f7}',
  sushi: '\u{1f363}',
  suspension_railway: '\u{1f69f}',
  svalbard_jan_mayen: '\u{1f1f8}\u{200d}\u{1f1ef}',
  swan: '\u{1f9a2}',
  swaziland: '\u{1f1f8}\u{200d}\u{1f1ff}',
  sweat: '\u{1f613}',
  sweat_drops: '\u{1f4a6}',
  sweat_smile: '\u{1f605}',
  sweden: '\u{1f1f8}\u{200d}\u{1f1ea}',
  sweet_potato: '\u{1f360}',
  swim_brief: '\u{1fa72}',
  swimmer: '\u{1f3ca}',
  swimming_man: '\u{1f3ca}\u{200d}\u{2642}',
  swimming_woman: '\u{1f3ca}\u{200d}\u{2640}',
  switzerland: '\u{1f1e8}\u{200d}\u{1f1ed}',
  symbols: '\u{1f523}',
  synagogue: '\u{1f54d}',
  syria: '\u{1f1f8}\u{200d}\u{1f1fe}',
  syringe: '\u{1f489}',
  't-rex': '\u{1f996}',
  taco: '\u{1f32e}',
  tada: '\u{1f389}',
  taiwan: '\u{1f1f9}\u{200d}\u{1f1fc}',
  tajikistan: '\u{1f1f9}\u{200d}\u{1f1ef}',
  takeout_box: '\u{1f961}',
  tanabata_tree: '\u{1f38b}',
  tangerine: '\u{1f34a}',
  tanzania: '\u{1f1f9}\u{200d}\u{1f1ff}',
  taurus: '\u{2649}',
  taxi: '\u{1f695}',
  tea: '\u{1f375}',
  teacher: '\u{1f9d1}\u{200d}\u{1f3eb}',
  technologist: '\u{1f9d1}\u{200d}\u{1f4bb}',
  teddy_bear: '\u{1f9f8}',
  telephone: '\u{260e}',
  telephone_receiver: '\u{1f4de}',
  telescope: '\u{1f52d}',
  tennis: '\u{1f3be}',
  tent: '\u{26fa}',
  test_tube: '\u{1f9ea}',
  thailand: '\u{1f1f9}\u{200d}\u{1f1ed}',
  thermometer: '\u{1f321}',
  thinking: '\u{1f914}',
  thought_balloon: '\u{1f4ad}',
  thread: '\u{1f9f5}',
  three: '\u{0033}\u{200d}\u{20e3}',
  thumbsdown: '\u{1f44e}',
  thumbsup: '\u{1f44d}',
  ticket: '\u{1f3ab}',
  tickets: '\u{1f39f}',
  tiger: '\u{1f42f}',
  tiger2: '\u{1f405}',
  timer_clock: '\u{23f2}',
  timor_leste: '\u{1f1f9}\u{200d}\u{1f1f1}',
  tipping_hand_man: '\u{1f481}\u{200d}\u{2642}',
  tipping_hand_person: '\u{1f481}',
  tipping_hand_woman: '\u{1f481}\u{200d}\u{2640}',
  tired_face: '\u{1f62b}',
  tm: '\u{2122}',
  togo: '\u{1f1f9}\u{200d}\u{1f1ec}',
  toilet: '\u{1f6bd}',
  tokelau: '\u{1f1f9}\u{200d}\u{1f1f0}',
  tokyo_tower: '\u{1f5fc}',
  tomato: '\u{1f345}',
  tonga: '\u{1f1f9}\u{200d}\u{1f1f4}',
  tongue: '\u{1f445}',
  toolbox: '\u{1f9f0}',
  tooth: '\u{1f9b7}',
  top: '\u{1f51d}',
  tophat: '\u{1f3a9}',
  tornado: '\u{1f32a}',
  tr: '\u{1f1f9}\u{200d}\u{1f1f7}',
  trackball: '\u{1f5b2}',
  tractor: '\u{1f69c}',
  traffic_light: '\u{1f6a5}',
  train: '\u{1f68b}',
  train2: '\u{1f686}',
  tram: '\u{1f68a}',
  triangular_flag_on_post: '\u{1f6a9}',
  triangular_ruler: '\u{1f4d0}',
  trident: '\u{1f531}',
  trinidad_tobago: '\u{1f1f9}\u{200d}\u{1f1f9}',
  tristan_da_cunha: '\u{1f1f9}\u{200d}\u{1f1e6}',
  triumph: '\u{1f624}',
  trolleybus: '\u{1f68e}',
  trophy: '\u{1f3c6}',
  tropical_drink: '\u{1f379}',
  tropical_fish: '\u{1f420}',
  truck: '\u{1f69a}',
  trumpet: '\u{1f3ba}',
  tshirt: '\u{1f455}',
  tulip: '\u{1f337}',
  tumbler_glass: '\u{1f943}',
  tunisia: '\u{1f1f9}\u{200d}\u{1f1f3}',
  turkey: '\u{1f983}',
  turkmenistan: '\u{1f1f9}\u{200d}\u{1f1f2}',
  turks_caicos_islands: '\u{1f1f9}\u{200d}\u{1f1e8}',
  turtle: '\u{1f422}',
  tuvalu: '\u{1f1f9}\u{200d}\u{1f1fb}',
  tv: '\u{1f4fa}',
  twisted_rightwards_arrows: '\u{1f500}',
  two: '\u{0032}\u{200d}\u{20e3}',
  two_hearts: '\u{1f495}',
  two_men_holding_hands: '\u{1f46c}',
  two_women_holding_hands: '\u{1f46d}',
  u5272: '\u{1f239}',
  u5408: '\u{1f234}',
  u55b6: '\u{1f23a}',
  u6307: '\u{1f22f}',
  u6708: '\u{1f237}',
  u6709: '\u{1f236}',
  u6e80: '\u{1f235}',
  u7121: '\u{1f21a}',
  u7533: '\u{1f238}',
  u7981: '\u{1f232}',
  u7a7a: '\u{1f233}',
  uganda: '\u{1f1fa}\u{200d}\u{1f1ec}',
  uk: '\u{1f1ec}\u{200d}\u{1f1e7}',
  ukraine: '\u{1f1fa}\u{200d}\u{1f1e6}',
  umbrella: '\u{2614}',
  unamused: '\u{1f612}',
  underage: '\u{1f51e}',
  unicorn: '\u{1f984}',
  united_arab_emirates: '\u{1f1e6}\u{200d}\u{1f1ea}',
  united_nations: '\u{1f1fa}\u{200d}\u{1f1f3}',
  unlock: '\u{1f513}',
  up: '\u{1f199}',
  upside_down_face: '\u{1f643}',
  uruguay: '\u{1f1fa}\u{200d}\u{1f1fe}',
  us: '\u{1f1fa}\u{200d}\u{1f1f8}',
  us_outlying_islands: '\u{1f1fa}\u{200d}\u{1f1f2}',
  us_virgin_islands: '\u{1f1fb}\u{200d}\u{1f1ee}',
  uzbekistan: '\u{1f1fa}\u{200d}\u{1f1ff}',
  v: '\u{270c}',
  vampire: '\u{1f9db}',
  vampire_man: '\u{1f9db}\u{200d}\u{2642}',
  vampire_woman: '\u{1f9db}\u{200d}\u{2640}',
  vanuatu: '\u{1f1fb}\u{200d}\u{1f1fa}',
  vatican_city: '\u{1f1fb}\u{200d}\u{1f1e6}',
  venezuela: '\u{1f1fb}\u{200d}\u{1f1ea}',
  vertical_traffic_light: '\u{1f6a6}',
  vhs: '\u{1f4fc}',
  vibration_mode: '\u{1f4f3}',
  video_camera: '\u{1f4f9}',
  video_game: '\u{1f3ae}',
  vietnam: '\u{1f1fb}\u{200d}\u{1f1f3}',
  violin: '\u{1f3bb}',
  virgo: '\u{264d}',
  volcano: '\u{1f30b}',
  volleyball: '\u{1f3d0}',
  vomiting_face: '\u{1f92e}',
  vs: '\u{1f19a}',
  vulcan_salute: '\u{1f596}',
  waffle: '\u{1f9c7}',
  wales:
    '\u{1f3f4}\u{200d}\u{e0067}\u{200d}\u{e0062}\u{200d}\u{e0077}\u{200d}\u{e006c}\u{200d}\u{e0073}\u{200d}\u{e007f}',
  walking: '\u{1f6b6}',
  walking_man: '\u{1f6b6}\u{200d}\u{2642}',
  walking_woman: '\u{1f6b6}\u{200d}\u{2640}',
  wallis_futuna: '\u{1f1fc}\u{200d}\u{1f1eb}',
  waning_crescent_moon: '\u{1f318}',
  waning_gibbous_moon: '\u{1f316}',
  warning: '\u{26a0}',
  wastebasket: '\u{1f5d1}',
  watch: '\u{231a}',
  water_buffalo: '\u{1f403}',
  water_polo: '\u{1f93d}',
  watermelon: '\u{1f349}',
  wave: '\u{1f44b}',
  wavy_dash: '\u{3030}',
  waxing_crescent_moon: '\u{1f312}',
  waxing_gibbous_moon: '\u{1f314}',
  wc: '\u{1f6be}',
  weary: '\u{1f629}',
  wedding: '\u{1f492}',
  weight_lifting: '\u{1f3cb}',
  weight_lifting_man: '\u{1f3cb}\u{200d}\u{2642}',
  weight_lifting_woman: '\u{1f3cb}\u{200d}\u{2640}',
  western_sahara: '\u{1f1ea}\u{200d}\u{1f1ed}',
  whale: '\u{1f433}',
  whale2: '\u{1f40b}',
  wheel_of_dharma: '\u{2638}',
  wheelchair: '\u{267f}',
  white_check_mark: '\u{2705}',
  white_circle: '\u{26aa}',
  white_flag: '\u{1f3f3}',
  white_flower: '\u{1f4ae}',
  white_haired_man: '\u{1f468}\u{200d}\u{1f9b3}',
  white_haired_woman: '\u{1f469}\u{200d}\u{1f9b3}',
  white_heart: '\u{1f90d}',
  white_large_square: '\u{2b1c}',
  white_medium_small_square: '\u{25fd}',
  white_medium_square: '\u{25fb}',
  white_small_square: '\u{25ab}',
  white_square_button: '\u{1f533}',
  wilted_flower: '\u{1f940}',
  wind_chime: '\u{1f390}',
  wind_face: '\u{1f32c}',
  wine_glass: '\u{1f377}',
  wink: '\u{1f609}',
  wolf: '\u{1f43a}',
  woman: '\u{1f469}',
  woman_artist: '\u{1f469}\u{200d}\u{1f3a8}',
  woman_astronaut: '\u{1f469}\u{200d}\u{1f680}',
  woman_cartwheeling: '\u{1f938}\u{200d}\u{2640}',
  woman_cook: '\u{1f469}\u{200d}\u{1f373}',
  woman_dancing: '\u{1f483}',
  woman_facepalming: '\u{1f926}\u{200d}\u{2640}',
  woman_factory_worker: '\u{1f469}\u{200d}\u{1f3ed}',
  woman_farmer: '\u{1f469}\u{200d}\u{1f33e}',
  woman_firefighter: '\u{1f469}\u{200d}\u{1f692}',
  woman_health_worker: '\u{1f469}\u{200d}\u{2695}',
  woman_in_manual_wheelchair: '\u{1f469}\u{200d}\u{1f9bd}',
  woman_in_motorized_wheelchair: '\u{1f469}\u{200d}\u{1f9bc}',
  woman_judge: '\u{1f469}\u{200d}\u{2696}',
  woman_juggling: '\u{1f939}\u{200d}\u{2640}',
  woman_mechanic: '\u{1f469}\u{200d}\u{1f527}',
  woman_office_worker: '\u{1f469}\u{200d}\u{1f4bc}',
  woman_pilot: '\u{1f469}\u{200d}\u{2708}',
  woman_playing_handball: '\u{1f93e}\u{200d}\u{2640}',
  woman_playing_water_polo: '\u{1f93d}\u{200d}\u{2640}',
  woman_scientist: '\u{1f469}\u{200d}\u{1f52c}',
  woman_shrugging: '\u{1f937}\u{200d}\u{2640}',
  woman_singer: '\u{1f469}\u{200d}\u{1f3a4}',
  woman_student: '\u{1f469}\u{200d}\u{1f393}',
  woman_teacher: '\u{1f469}\u{200d}\u{1f3eb}',
  woman_technologist: '\u{1f469}\u{200d}\u{1f4bb}',
  woman_with_headscarf: '\u{1f9d5}',
  woman_with_probing_cane: '\u{1f469}\u{200d}\u{1f9af}',
  woman_with_turban: '\u{1f473}\u{200d}\u{2640}',
  womans_clothes: '\u{1f45a}',
  womans_hat: '\u{1f452}',
  women_wrestling: '\u{1f93c}\u{200d}\u{2640}',
  womens: '\u{1f6ba}',
  woozy_face: '\u{1f974}',
  world_map: '\u{1f5fa}',
  worried: '\u{1f61f}',
  wrench: '\u{1f527}',
  wrestling: '\u{1f93c}',
  writing_hand: '\u{270d}',
  x: '\u{274c}',
  yarn: '\u{1f9f6}',
  yawning_face: '\u{1f971}',
  yellow_circle: '\u{1f7e1}',
  yellow_heart: '\u{1f49b}',
  yellow_square: '\u{1f7e8}',
  yemen: '\u{1f1fe}\u{200d}\u{1f1ea}',
  yen: '\u{1f4b4}',
  yin_yang: '\u{262f}',
  yo_yo: '\u{1fa80}',
  yum: '\u{1f60b}',
  zambia: '\u{1f1ff}\u{200d}\u{1f1f2}',
  zany_face: '\u{1f92a}',
  zap: '\u{26a1}',
  zebra: '\u{1f993}',
  zero: '\u{0030}\u{200d}\u{20e3}',
  zimbabwe: '\u{1f1ff}\u{200d}\u{1f1fc}',
  zipper_mouth_face: '\u{1f910}',
  zombie: '\u{1f9df}',
  zombie_man: '\u{1f9df}\u{200d}\u{2642}',
  zombie_woman: '\u{1f9df}\u{200d}\u{2640}',
  zzz: '\u{1f4a4}',
}

export const emojiList: Emoji[] = Object.entries(emojiCharMap).map(([key, value]) => ({name: key, character: value}))
